import React, { useState, useEffect, useContext, forwardRef } from 'react';

import { useRouter } from 'next/router';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import UserContext from '@components/lpc/Context/User/UserContext';
import ParticipateForm from '@components/lpc/Pot/AmountModule/Participate/ParticipateForm/ParticipateForm';
import { personRoles } from '@models/PotModel/personRoles';
import { statusPO } from '@models/PotModel/statusPO';
import { participationDataType, poolContributionType } from '@propTypes/potTypes';
import potService from '@services/domain/Lpc/PotService';
import { isEmpty } from '@services/utils/ObjectService';

import css from './Participate.module.scss';

type ParticipateProps = {
    potRef: string;
    poolContribution: poolContributionType;
    customCta?: string;
    customChildren?: string;
    customVariant?: string;
    customContainer?: string;
    personRole?: string;
    icon?: string;
    isFrozen?: boolean;
    potStatus?: string;
    onParticipateClick?: () => void;
    isUnderlinedTextParticipate?: boolean;
    children?: React.ReactNode;
};

const Participate = forwardRef(
    (
        {
            children,
            potRef,
            potStatus,
            poolContribution,
            customCta,
            customChildren,
            customVariant,
            customContainer,
            isFrozen,
            personRole,
            onParticipateClick,
            icon,
            isUnderlinedTextParticipate,
        }: ParticipateProps,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const { t } = useTranslateLpc(['lpc-pot']);
        const router = useRouter();

        const userContext = useContext(UserContext);
        const { userAccount } = userContext;

        const [openParticipate, setOpenParticipate] = useState(false);
        const [participationRetry, setParticipationRetry] = useState<participationDataType>(null);

        useEffect(() => {
            router.query.participate && setOpenParticipate(true);
        }, [router.query.participate]);

        useEffect(() => {
            if (router.query.participateRetry) {
                potService.getPaymentResponse(router.query.participateRetry.toString()).then((res) => {
                    setParticipationRetry(res);
                    setOpenParticipate(true);
                });
            }
        }, [router.query.participateRetry]);

        return (
            <>
                {!isEmpty(poolContribution) && (
                    <ParticipateForm
                        participationRetry={participationRetry}
                        user={userAccount}
                        potRef={potRef}
                        poolContribution={poolContribution}
                        isVisible={openParticipate}
                        onHide={() => setOpenParticipate(false)}
                    />
                )}

                <div ref={ref} className={customContainer || css.participate} data-tip data-tooltip-id="registerTip">
                    {isUnderlinedTextParticipate ? (
                        <h5
                            className={isFrozen ? css.participate__cta__frozen : ''}
                            onClick={() => {
                                if (!isFrozen && potStatus !== statusPO.CLOSED) {
                                    setOpenParticipate(true);
                                    onParticipateClick();
                                }
                            }}
                        >
                            {t('activity.first-participation-participate')}
                        </h5>
                    ) : (
                        <Button
                            onClick={() => {
                                setOpenParticipate(true);
                                onParticipateClick();
                            }}
                            isDisabled={isFrozen || potStatus === statusPO.CLOSED}
                            variant={customVariant || 'primary'}
                            customClass={`${css.participate__cta} ${customCta || ''}`}
                            reverseIcon={icon}
                        >
                            <div className={customChildren}>
                                {t('amount.cta.participate')}
                                {children}
                            </div>
                        </Button>
                    )}

                    <ReactTooltip hidden={!isFrozen || potStatus !== statusPO.CLOSED} id="registerTip" place="top">
                        {t(
                            `header.frozen-account-${personRole === personRoles.PARTICIPANT ? 'participant' : 'organizer'}`,
                        )}
                    </ReactTooltip>
                </div>
            </>
        );
    },
);
Participate.displayName = 'Participate';
export default Participate;
