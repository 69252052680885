import { potType } from '@propTypes/potTypes';

export const calculatePercentage = (partialValue, totalValue) => {
    if (partialValue > totalValue) {
        return 100;
    }

    return (100 * partialValue) / totalValue;
};

export const roundThreeDigits = (amount: number): number => {
    if (amount === 0) {
        return 0;
    }
    return Math.round(amount * 1000) / 1000;
};

export const computeFees = (baseAmount, pot: potType): number => {
    if (baseAmount === 0) {
        return 0;
    }

    if (pot.commissionType !== 'COMMISSION_IN') {
        return fixFloat(baseAmount * pot.comOutMax);
    }

    let com;
    if (pot.commissionFloor !== 0 && baseAmount >= pot.commissionFloor) {
        com = baseAmount * pot.commission2;
    } else {
        com = baseAmount * pot.commission1;
    }
    if (pot.commissionMin !== 0 && com < pot.commissionMin) {
        com = pot.commissionMin;
    }
    return fixFloat(com);
};

export const fixFloat = (num): number => {
    if (num === 0) {
        return 0;
    }
    return Math.round(Math.round((Math.round(num * 100000) / 100000) * 100 * 1000) / 1000) / 100;
};

export default { calculatePercentage, roundThreeDigits };
