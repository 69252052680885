import React, { useContext, useState } from 'react';

import { Form, Formik } from 'formik';
import DOMPurify from 'isomorphic-dompurify';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useToasts } from 'react-toast-notifications';

import Button from '@components/common/Button/Button';
import Checkbox from '@components/common/Formik/FormikCheckBox';
import Input from '@components/common/Formik/FormikInputField';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Modal from '@components/common/Modals/Modal/Modal';
import Text from '@components/common/Text/Text';
import PotContext from '@components/lpc/Context/Pot/PotContext';
import { participationDataType, poolContributionType } from '@propTypes/potTypes';
import potService from '@services/domain/Lpc/PotService';
import { getRoute, ROUTE } from '@services/http/Route';
import { computeFees } from '@services/utils/NumberService';
import ObjectService from '@services/utils/ObjectService';
import { setItemToStorage } from '@services/utils/StorageService';
import { formatCurrency } from '@services/utils/StringService';
import { freeParticipationSchema, participationSchema } from '@validations/participationSchema';

import css from './ParticipateForm.module.scss';

type ParticipateFormProps = {
    isVisible: boolean;
    onHide: () => void;
    potRef: string;
    user: { firstName: string; lastName: string; email: string };
    poolContribution: poolContributionType;
    participationRetry: participationDataType;
};

const ParticipateForm = ({
    isVisible,
    onHide,
    potRef,
    user,
    poolContribution,
    participationRetry,
}: ParticipateFormProps) => {
    const { t } = useTranslateLpc(['lpc-pot', 'lpc-authentication', 'common']);
    const router = useRouter();
    const { addToast } = useToasts();
    const potContext = useContext(PotContext);
    const { updatedPot } = potContext;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { contributionType, contributionAmount } = poolContribution;

    const getValidationSchema = () => {
        return !ObjectService.isEmpty(user) ? participationSchema : freeParticipationSchema;
    };

    const getInitialValues = () => {
        if (participationRetry) {
            return {
                firstName: participationRetry.firstName || '',
                lastName: participationRetry.lastName || '',
                email: participationRetry.email || '',
                address: participationRetry.address || '',
                contributionAmount:
                    contributionType === 'FIXED' ? contributionAmount : participationRetry.totalAndCom || '',
                optInRequired: false,
                confirmNewsletter: false,
                hideName: participationRetry.hideName || false,
                hideContribution: participationRetry.hideContribution || false,
                askParticipantsForAddress: updatedPot.privacy?.askParticipantsForAddress,
            };
        } else {
            return {
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                email: user?.email || '',
                address: '',
                contributionAmount: contributionType === 'FIXED' ? contributionAmount : '',
                optInRequired: false,
                confirmNewsletter: false,
                askParticipantsForAddress: updatedPot.privacy?.askParticipantsForAddress,
            };
        }
    };

    const onSuccess = (values, res) => {
        const participation = {
            user: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                confirmNewsletter: values.confirmNewsletter,
            },
            callback: res,
        };

        setItemToStorage('session', 'participate', participation);
        router.push(getRoute(ROUTE.LPC.POT.POT, potRef, ROUTE.LPC.POT.PAYMENT, res.orderId)).then();
    };

    const onSubmit = (values: {
        firstName: string;
        lastName: string;
        email: string;
        address: string;
        contributionAmount: number | string;
        optInRequired: boolean;
        confirmNewsletter: boolean;
    }) => {
        setIsLoading(true);
        potService
            .participate(potRef, values)
            .then((res) => onSuccess(values, res))
            .catch((err) => {
                addToast(t(`errors.${err.isDefault ? 'SMONEY_GENERIC_ERROR' : err.code}`), {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
            .finally(() => {
                setIsLoading(false);
                onHide();
            });
    };

    return (
        <Modal visible={isVisible} onHide={onHide} customClass={css.custom}>
            <div className={css.header}>
                <h5>{t('participate.title')}</h5>
            </div>

            <Formik
                initialValues={getInitialValues()}
                validationSchema={getValidationSchema()}
                onSubmit={(values) => onSubmit(values)}
                validateOnMount={true}
                enableReinitialize={true}
            >
                {({ values, isValid }) => (
                    <Form className={css.form}>
                        <div className={css.form__content}>
                            {ObjectService.isEmpty(user) && (
                                <>
                                    <Link href={getRoute(`${ROUTE.LPC.AUTH.SIGN_IN_PARTICIPATE}${potRef}`)}>
                                        <Button variant={'secondary'} customClass={css.form__submit__cta}>
                                            {t('participate.cta.sign-in')}
                                        </Button>
                                    </Link>
                                    <Text customClass={css.divider} variant={'caption_01'} color={'txt-secondary'}>
                                        <span>{t('login.or')}</span>
                                    </Text>
                                </>
                            )}

                            <div className={css.form__container_input}>
                                <div className={`${css.form__input} ${css.form__container_input__size}`}>
                                    <Input
                                        name="firstName"
                                        type="text"
                                        label={t('participate.firstName-label')}
                                        isFloatingLabel
                                    />
                                </div>
                                <div className={`${css.form__input} ${css.form__container_input__size}`}>
                                    <Input
                                        name="lastName"
                                        type="text"
                                        label={t('participate.lastName-label')}
                                        isFloatingLabel
                                    />
                                </div>
                            </div>
                            <div className={css.form__input}>
                                <Input name="email" type="email" label={t('participate.email-label')} isFloatingLabel />
                            </div>

                            {contributionType !== 'FIXED' && (
                                <div className={css.form__input}>
                                    <Input
                                        name="contributionAmount"
                                        type="number"
                                        isAmount
                                        label={t('participate.amount-label')}
                                        placeholder={
                                            contributionType === 'SUGGESTED'
                                                ? t('participate.amount-suggested-placeholder', {
                                                      suggestedAmount: contributionAmount,
                                                  })
                                                : t('participate.amount-placeholder')
                                        }
                                        isFloatingLabel
                                    />
                                </div>
                            )}

                            {updatedPot.privacy?.askParticipantsForAddress && (
                                <div className={css.form__input}>
                                    <Input
                                        name="address"
                                        type="text"
                                        label={t('participate.address-label')}
                                        placeholder={t('participate.address-placeholder')}
                                        isFloatingLabel
                                    />
                                </div>
                            )}

                            {!updatedPot.commissionIncluded && values.contributionAmount && false && (
                                <>
                                    <div className={css.form__fees_container}>
                                        <Text variant={'caption_00'} color={'txt-primary'}>
                                            {t('participate.fees')}
                                        </Text>
                                        <Text variant={'caption_00'} color={'txt-primary'}>
                                            {formatCurrency(computeFees(values.contributionAmount, updatedPot))}
                                        </Text>
                                    </div>
                                    <div className={css.form__fees_container}>
                                        <Text variant={'caption_00'} color={'txt-primary'}>
                                            {t('participate.total')}
                                        </Text>
                                        <Text variant={'caption_00'} color={'txt-primary'}>
                                            {formatCurrency(
                                                computeFees(values.contributionAmount, updatedPot) +
                                                    Number(values.contributionAmount),
                                            )}
                                        </Text>
                                    </div>
                                </>
                            )}

                            <div className={css.form__checkbox}>
                                <div className={css.form__input}>
                                    <Checkbox name="hideContribution">{t('participate.contribution-label')}</Checkbox>
                                </div>
                                <div className={css.form__input}>
                                    <Checkbox name="hideName">{t('participate.name-label')}</Checkbox>
                                </div>
                                {ObjectService.isEmpty(user) && (
                                    <>
                                        <div className={css.form__input}>
                                            <Checkbox name="confirmNewsletter">
                                                {t('participate.newsletter-label')}
                                            </Checkbox>
                                        </div>
                                        <div className={css.form__input}>
                                            <Checkbox name="optInRequired">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            t('participate.cgu-label', {
                                                                termsLpc: ROUTE.LPC.CGU,
                                                                privacyPolicyLPC: ROUTE.LPC.PRIVACY_POLITY,
                                                            }),
                                                            { ADD_ATTR: ['target'] },
                                                        ),
                                                    }}
                                                />
                                            </Checkbox>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* SUBMIT */}
                            <div className={css.form__submit}>
                                <Button
                                    type={'submit'}
                                    variant={'primary'}
                                    customClass={css.form__submit__cta}
                                    isLoading={isLoading}
                                    isDisabled={!isValid || isLoading}
                                >
                                    {contributionType === 'FIXED'
                                        ? t('participate.cta.participate-fixed', {
                                              fixedAmount: contributionAmount,
                                          })
                                        : t('participate.cta.participate')}
                                </Button>
                            </div>
                            <Text customClass={css.form__terms} variant={'caption_02'} color={'ui-secondary'}>
                                <img src="/front-static/icons/display/secure.svg" alt="secure" />
                                {t('participate.terms')}
                            </Text>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ParticipateForm;
