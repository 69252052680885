const yup = require('yup');

const name = yup
    .string()
    .matches(
        /^((?!\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]).)*$/,
        'EMOTES_NOT_SUPPORTED',
    )
    .min(2, 'MIN_LENGTH')
    .max(60, 'MAX_LENGTH');

const contributionAmount = yup.number().typeError('MUST_BE_NUMBER').min(1, 'MIN').max(3000, 'MAX');

const email = yup.string().min(5, 'MIN_LENGTH').max(130, 'MAX_LENGTH').email('INVALID');

const optInRequired = yup.bool().oneOf([true], 'MUST_BE_CHECKED').required('REQUIRED');

const address = yup.string().when('askParticipantsForAddress', {
    is: true,
    then: yup.string().required('REQUIRED'),
});

export const participationSchema = yup.object({
    firstName: name.required('REQUIRED'),
    lastName: name.required('REQUIRED'),
    email: email.required('REQUIRED'),
    address: address,
    contributionAmount: contributionAmount.required('REQUIRED'),
});

export const freeParticipationSchema = yup.object({
    firstName: name.required('REQUIRED'),
    lastName: name.required('REQUIRED'),
    email: email.required('REQUIRED'),
    contributionAmount: contributionAmount.required('REQUIRED'),
    optInRequired,
});
